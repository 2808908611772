import { datadogRum } from '@datadog/browser-rum';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useRouter } from 'next/router';
import React, { useEffect } from 'react';

import LandingPage from '@/components/LandingPage/LandingPage';
import SimpleLoader from '@/components/Loader/SimpleLoader';
import { COMPANY_NAME } from '@/constants/constants';
import { Meta } from '@/layouts/Meta';
import { CenteredContainer } from '@/styles/shared';
import { Main } from '@/templates/Main';
import { AppConfig } from '@/utils/AppConfig';

interface FeatureFlags {
  bypassGetStarted?: boolean;
}

const Index = () => {
  const { bypassGetStarted = false } = useFlags<FeatureFlags>() || {};

  const router = useRouter();

  useEffect(() => {
    if (bypassGetStarted) {
      router.push('/direct-to-initial/schedule');
      datadogRum.addAction('bypassGetStarted', {
        feature: 'launchDarkly',
        environment: AppConfig.env,
      });
    }
  }, [bypassGetStarted]);

  return (
    <Main
      meta={
        <Meta
          title={`${COMPANY_NAME} - Let's Get Started`}
          description="Schedule your first appointment."
        />
      }
    >
      {bypassGetStarted ? (
        <CenteredContainer data-testid="simple-loader">
          <SimpleLoader color="green" />
        </CenteredContainer>
      ) : null}
      {!bypassGetStarted ? <LandingPage /> : null}
    </Main>
  );
};

export default Index;
